// always include js/app/Main as first
import mainApp from 'js/app/Main';

// // now load the complete CSS htmlstream Front based custom theme
// import 'css/_integration/html-stream-theme.scss';
// // backport some metronic 7 stuff
// import 'js/_integration/metronic-7/integration';
//
// import 'css/app.scss';
// import 'css/user.scss';
// import 'css/promotion.scss';
//
// import 'js/app/config/app';

mainApp.init().done(() => {


}).fail(() => {
  console.error('Main app initialization failed!');
});
